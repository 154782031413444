import React, {PureComponent, ReactNode} from "react";
import RegisternowImage from "../../../public/assets/images/werkstattbesitzer.webp";

export type RegisterNowProps = {
    headline: string,
    text: string,
};

export class RegisterNow extends PureComponent<RegisterNowProps> {

    render(): ReactNode {
        const {
            headline,
            text,
        } = this.props

        return (
            <div
                className={`registerNow mx-4 md:mx-12 mb-12 grid grid-cols-3 gap-x-24 bg-gray-700 rounded-md`}>
                <picture
                    className={'aboutUs__ImageBlock col-span-3 md:col-span-1 w-full h-full min-h-[155px] aspect-auto'}>
                    <img loading={'lazy'} className={'object-cover min-h-full w-full rounded-md'}
                         src={RegisternowImage.src} alt="Werkstattbesitzer" width={300} height={204}/>
                </picture>
                <div className={'registerNow__textBlock col-span-3 md:col-span-2 py-10 px-8 md:px-0 lg:py-20 grid grid-cols-1 lg:grid-cols-2'}>
                    <div className={'col-span-1'}>
                        <span className={'bg-green-100 rounded-full py-2 px-4 text-green-800'}><b className={'text-green-500'}>NEU</b> Buchungen aus Google und Maps</span>
                        <h2 className={`text-3xl leading-9 font-semibold md:text-4xl md:leading-10 font-serif text-white py-4`}>{headline}
                        </h2>
                        <p className={'text-lg leading-7 font-normal font-serif text-gray-200 pb-8'}>{text}</p>
                    </div>
                    <div className={"button text-base leading-6 font-medium relative col-span-1 lg:content-center lg:mx-auto lg:my-0"}>
                        <a href={'https://lp.repareo.de/ws/registrierung-ihrer-werkstatt'}
                            className={`bg-white rounded-md w-full md:w-auto inline-block text-center p-2 text-gray-900 w-full lg:w-auto mb-8 lg:mb-0 px-5 py-3 no-underline`}>Unsere
                            Lösung entdecken
                        </a>
                    </div>
                </div>
            </div>
        );
    }
}