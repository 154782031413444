import type {NextPage} from 'next'
import Head from 'next/head'
import {GoogleTagManager} from "../components/common/GoogleTagManager";
import React from "react";
import {HeaderProps} from "components/Home/ContentElements/Header";
import dynamic from "next/dynamic";
import {HeroProps} from "../components/Home/ContentElements/Hero";
import {PopularServicesProps} from "../components/Home/ContentElements/PopularServices";
import {BookNowProps} from "../components/Home/ContentElements/BookNow";
import {PartnerIconCollectionProps} from "../components/Home/ContentElements/PartnerIconCollection";
import {AboutUsProps} from "../components/Home/ContentElements/AboutUs";
import {ServicesProps} from "../components/Home/ContentElements/Services";
import {BookingAdvantagesProps} from "../components/Home/ContentElements/BookingAdvantages";
import {GarageIconCollectionProps} from "../components/Home/ContentElements/GarageIconCollection";
import {GaragePartnersProps} from "../components/Home/ContentElements/GaragePartners";
const Header = dynamic<HeaderProps>(() => import('../components/Home/ContentElements/Header').then(mod => mod.Header))
const Hero = dynamic<HeroProps>(import("../components/Home/ContentElements/Hero").then(mod => mod.Hero))
const GaragePartners = dynamic<GaragePartnersProps>(import("../components/Home/ContentElements/GaragePartners").then(mod => mod.GaragePartners))
const GarageIconCollection = dynamic<GarageIconCollectionProps>(import("../components/Home/ContentElements/GarageIconCollection").then(mod => mod.GarageIconCollection))
const BookingAdvantages = dynamic<BookingAdvantagesProps>(import("../components/Home/ContentElements/BookingAdvantages").then(mod => mod.BookingAdvantages))
const Services = dynamic<ServicesProps>(import("../components/Home/ContentElements/Services").then(mod => mod.Services))
const AboutUs = dynamic<AboutUsProps>(import("../components/Home/ContentElements/AboutUs").then(mod => mod.AboutUs))
const PartnerIconCollection = dynamic<PartnerIconCollectionProps>(import("../components/Home/ContentElements/PartnerIconCollection").then(mod => mod.PartnerIconCollection))
const BookNow = dynamic<BookNowProps>(import("../components/Home/ContentElements/BookNow").then(mod => mod.BookNow))
const PopularServices = dynamic<PopularServicesProps>(import("../components/Home/ContentElements/PopularServices").then(mod => mod.PopularServices))
const Footer = dynamic<{}>(import("../components/Home/ContentElements/Footer").then(mod => mod.Footer))
import {AdacSection} from "../components/Home/ContentElements/AdacSection";
import {RegisterNow} from "@App/components/Home/ContentElements/RegisterNow";

const Home: NextPage = () => {
    return (
        <div data-theme="repareo">
            <Head>
                <title>Die beste Werkstatt in deiner Nähe finden | repareo</title>
                <meta name="description"
                      content="Mit repareo findest du schnell und einfach die beste Werkstatt in deiner Nähe. Vergleiche Preise, Leistungen und Termine. Buche Werkstatttermin einfach online."/>
                <meta name="viewport" content="width=device-width, initial-scale=1"/>
                <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png"/>
                <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png"/>
                <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png"/>
                <link rel="manifest" href="/site.webmanifest"/>
                <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5"/>
                <meta name="msapplication-TileColor" content="#da532c"/>
                <meta name="theme-color" content="#ffffff"/>
                <link rel="canonical" href="https://www.repareo.de"/>
            </Head>
            <GoogleTagManager/>
            <main>
                <div className={`home max-w-screen-xl my-0 mx-auto`}>
                    <Header/>
                    <Hero/>
                    <RegisterNow headline={'Sie sind Werkstattbesitzer?'} text={'Erreichen Sie neue profitable Kundengruppen und optimieren Sie Ihre Auslastung'}/>
                    <GaragePartners headline={'Unsere Werkstattpartner stehen für Qualität'} text={'Über 251.428 Bewertungen von Kunden mit durchschnittlich 4.45 von 5 Sternen'}/>
                    <GarageIconCollection headline={'In Deutschland einzigartig: Unser Werkstattnetz'}/>
                    <BookingAdvantages headline={'Buche auf repareo und komme entspannt zum Werkstatttermin'}/>
                    <Services headline={'Beliebte Werkstatt-Services'}/>
                    <AboutUs headline={'Wir machen Werkstattbuchung digital und transparent'} text={'repareo ist die einzige Plattform in Deutschland, auf der du tausende von Werkstätten direkt online buchen kannst. Wir machen es dir leicht, die beste Werkstatt in deiner Nähe zu finden und zu buchen. Damit wird die Werkstattbuchung so einfach wie noch nie.'}/>
                    <AdacSection />
                    <PartnerIconCollection headline={'Zu unseren Partnern gehören'}/>
                    <BookNow headline={'Bereit zu buchen?'} headlineAddition={'Finde die beste Werkstatt in deiner Nähe'} text={'Vereinbare Termine für Autohäuser, freie Werkstätten und Werkstattketten direkt online und mit transparenten Preisen.'}/>
                    <PopularServices headline={'Beliebte Services'}/>
                    <Footer/>
                </div>
            </main>

            <footer>

            </footer>
        </div>
    )
}

export default Home
